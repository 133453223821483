// @flow
import type { RecoilState } from 'recoil';
import { useEffect } from 'react';
import { atom, useRecoilState, useRecoilValue } from 'recoil';
import Cookie from 'js-cookie';
import { env } from '../config/env';
import { ROLES_COOKIE_NAME } from 'config';
import { logger } from 'modules/logger';

type Role = {
  name: string,
  scope: {
    name: string,
    object_id: string,
  },
  permissions: {
    name: string,
    component: string,
  }[],
};

const rolesState: RecoilState<Role[] | null> = atom<Role[] | null>({
  key: 'userRoles',
  default: null,
});

const DEPLOY_ENV = env.DEPLOY_ENV;

const getCookieRoles = () => Cookie.get(ROLES_COOKIE_NAME);

export const useInitializeRoles = (isAuthenticated: boolean): void => {
  const [recoilRoles, setRecoilRoles] = useRecoilState(rolesState);

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }

    if (recoilRoles == null) {
      const cookieRoles = getCookieRoles();
      try {
        if (cookieRoles == null) {
          throw new Error('No roles found in cookie');
        }

        const roles = JSON.parse(cookieRoles);
        setRecoilRoles(roles);
      } catch (error) {
        console.warn('Error parsing roles from cookie:', error);
        console.warn('Attempted to parse:', cookieRoles);

        setRecoilRoles([]);
      }
    }
  }, [isAuthenticated, recoilRoles, setRecoilRoles]);

  // Log the roles in non-production environments, if available
  useEffect(() => {
    if (isAuthenticated && DEPLOY_ENV !== 'production') {
      logger.info('current user roles:', recoilRoles);
    }
  }, [isAuthenticated, recoilRoles]);
};

/** Returns the user's roles from a Recoil atom when initialized */
export const useGetUserRoles = (): Role[] | null => {
  const recoilRoles = useRecoilValue(rolesState);
  return recoilRoles;
};
